<template>
  <v-dialog
    class="help"
    v-model="help.toggle"
    scrollable
    fullscreen 
    hide-overlay 
    transition="scroll-x-reverse-transition"
    light
  >
    <v-card class="sheet">
      <div>
        <v-toolbar
          dark
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="help.toggle = false"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
          <v-toolbar-title>
            Ajuda
          </v-toolbar-title>
        </v-toolbar>
      </div>

      <v-card-text class="help-content">
        <h6 class="title subtitle-1 px-6 mb-4">
          {{ title }}
        </h6>

        <v-divider class="my-0" />

        <items />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>

  .help-content {
    padding: 24px 0 !important;
  }

  .help-content .title {
    color: var(--mobees-black);
    font-weight: 500;
  }
  
</style>

<script>

  // Icons
  import {
    mdiClose,
    mdiChevronLeft,
  } from '@mdi/js'

  // Utilities
  import { sync } from 'vuex-pathify'


  export default {
    name: 'Help',

    components: {
    },

    data: () => ({
      icons: {
        back: mdiChevronLeft,
        close: mdiClose,
      },
      title: ''
    }),

    computed: {
      help: sync('help'),
    },

  }
</script>
